import queries from 'styles/breakpoints'
import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as IconView } from 'assets/shared/icon-view-image.svg'
import { currentSlideIndex, currentSlide } from 'store/slidesSlice'
import { openLightBox } from 'store/lightboxSlice'
import { setSlideFooterVisibility } from 'store/slideFooterSlice'

import {
  Button,
  Description,
  Figure,
  Link,
  PictureParams,
  Name,
  List,
  GoToShop,
} from './Slide.styles'
import Macy from 'macy'
import { useEffect } from 'react'

const macyOptions = {
  container: '#macy-grid',
  trueOrder: false,
  mobileFirst: true,
  useContainerForBreakpoints: true,
  margin: 24,
  boxSizing: 'border-box',
  columns: 1,
  breakAt: {
    1000: {
      margin: 16,
      columns: 2,
    },
    650: {
      margin: 16,
      columns: 1,
    },
  },
}

const Slide = (): JSX.Element => {
  const dispatch = useDispatch()
  const currentIndex = useSelector(currentSlideIndex)
  const current = useSelector(currentSlide)

  const handleSlideComponentAction = () => {
    dispatch(setSlideFooterVisibility(true))
  }

  useEffect(() => {
    new Macy(macyOptions)
  }, [currentIndex])

  return (
    <List
      id="macy-grid"
      key={currentIndex}
      onTouchStart={handleSlideComponentAction}
    >
      <div>
        <Figure>
          <picture>
            <source media={queries.tabletUp} srcSet={current.images.artWork} />
            <img src={current.images.artWork} alt="" />
            <Button onClick={() => dispatch(openLightBox())}>
              <IconView />
            </Button>
          </picture>
        </Figure>
      </div>
      <div>
        <Name>{current.name}</Name>
        <PictureParams>
          {current.parameters.type}, {current.parameters.material},{' '}
          {current.parameters.size}, {current.year}
        </PictureParams>
        <Description>{current.description}</Description>
        {current.source.startsWith('https') ? (
          <GoToShop>
            <Link
              href={current.source}
              target="_blank"
              rel="noopener noreferrer"
            >
              Go to shop
            </Link>
          </GoToShop>
        ) : (
          <GoToShop>{current.source}</GoToShop>
        )}
      </div>
    </List>
  )
}

export default Slide
