import { RefCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ProgressBar from 'components/Gallery/atoms/ProgressBar'
import { ReactComponent as NextButton } from 'assets/shared/icon-next-button.svg'
import { ReactComponent as PrevButton } from 'assets/shared/icon-back-button.svg'
import { paginate } from 'store/slidesSlice'
import { currentSlideIndex, numberOfSlides } from 'store/slidesSlice'
import {
  setSlideFooterVisibility,
  selectSlideFooterVisibility,
} from 'store/slideFooterSlice'
import {
  ArrowButtonLeft,
  ArrowButtonRight,
  Footer,
  Wrapper,
} from './SlideFooter.styles.'
import { footerAnimation } from 'utils/animations'
import { useSwipeable } from 'react-swipeable'
import { useAnimation } from 'framer-motion'

const SlideFooter = (): JSX.Element => {
  const isSlideFooterVisible = useSelector(selectSlideFooterVisibility)
  const currentIndex = useSelector(currentSlideIndex)
  const slidesLength = useSelector(numberOfSlides)
  const controls = useAnimation()
  const dispatch = useDispatch()

  const progressBarWidth = Math.round(((currentIndex + 1) / slidesLength) * 100)

  const handleSwipeLeft = () => {
    dispatch(paginate(1))
  }

  const handleSwipeRight = () => {
    dispatch(paginate(-1))
  }

  useEffect(() => {
    if (isSlideFooterVisible) {
      if (window.innerWidth <= 650) {
        controls.start('visible')
        setTimeout(() => {
          controls.start('hidden')
          dispatch(setSlideFooterVisibility(false))
        }, 3000)
      }
    }
  }, [controls, dispatch, isSlideFooterVisible])

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.key === 'ArrowLeft') {
        dispatch(paginate(-1))
      } else if (e.key === 'ArrowRight') {
        dispatch(paginate(1))
      }
    }
    document.addEventListener('keydown', handleKeyPress)
    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, [dispatch])

  const { ref: documentRef } = useSwipeable({
    onSwipedLeft: handleSwipeLeft,
    onSwipedRight: handleSwipeRight,
  }) as { ref: RefCallback<Document> }

  useEffect(() => {
    if (window.innerWidth <= 650) {
      documentRef(document)
      return () => {
        documentRef(null)
      }
    } else {
      controls.start('visible')
    }
  }, [controls, documentRef])

  return (
    <Wrapper
      exit="exit"
      initial="hide"
      aria-live="polite"
      animate={controls}
      variants={footerAnimation}
    >
      <ProgressBar width={progressBarWidth} />
      <Footer>
        <ArrowButtonLeft
          aria-label="Previous Slide"
          onClick={() => dispatch(paginate(-1))}
        >
          <PrevButton />
        </ArrowButtonLeft>
        <ArrowButtonRight
          aria-label="Next Slide"
          onClick={() => dispatch(paginate(1))}
        >
          <NextButton />
        </ArrowButtonRight>
      </Footer>
    </Wrapper>
  )
}

export default SlideFooter
