import styled from 'styled-components/macro'
import Macy from 'macy'
import { motion } from 'framer-motion'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import queries from 'styles/breakpoints'
import data from 'data/data.json'
import GalleryCard from 'components/Gallery/molecules/GalleryCard'
import { pageAnimation, galleryAnimation } from 'utils/animations'
import { resetSlider, setFilteredSlides } from 'store/slidesSlice'

const Wrapper = styled(motion.main)`
  padding: 1.5rem;
  margin: 0 auto;
  max-width: var(--max-width);
  @media ${queries.tabletUp} {
    padding-top: 2.5rem;
  }
`

const List = styled(motion.ul)`
  margin: 0;

  img {
    width: 100%;
  }
`

const macyOptions = {
  container: '#macy-grid',
  trueOrder: true,
  mobileFirst: true,
  margin: 24,
  boxSizing: 'border-box',
  columns: 1,
  breakAt: {
    1000: 4,
    650: {
      margin: 16,
      columns: 2,
    },
  },
}

const Gallery = ({ type }: { type?: string }): JSX.Element => {
  const dispatch = useDispatch()
  const filteredData = type
    ? data.filter(painting => painting.parameters.type === type)
    : data

  useEffect(() => {
    dispatch(resetSlider())
  }, [dispatch])

  useEffect(() => {
    new Macy(macyOptions)
    dispatch(setFilteredSlides(filteredData))
  }, [dispatch, filteredData, type])

  return (
    <Wrapper
      key={type}
      exit="exit"
      variants={pageAnimation}
      initial="hide"
      animate="show"
    >
      <List
        id="macy-grid"
        initial="hide"
        animate="show"
        variants={galleryAnimation}
      >
        {filteredData.map((painting, index) => {
          return <GalleryCard {...painting} id={index} key={index} />
        })}
      </List>
    </Wrapper>
  )
}

export default Gallery
