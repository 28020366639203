import { Variants } from 'framer-motion/dist/framer-motion'

export const pageAnimation = {
  hide: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    transition: {
      duration: 1,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 1,
    },
  },
}
export const messageIconAnimation = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
}

export const footerAnimation = {
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      duration: 0.5,
    },
  },
}

export const slideAnimation = (direction: number): Variants => {
  return {
    enter: {
      position: 'absolute',
      x: direction > 0 ? '100%' : '-100%',
      opacity: 0,
    },
    center: {
      position: 'relative',
      x: '0%',
      opacity: 1,
    },
    exit: {
      position: 'absolute',
      x: direction < 0 ? '100%' : '-100%',
      opacity: 0,
    },
  }
}

export const galleryAnimation = {
  hide: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.25,
      ease: 'easeOut',
      delayChildren: 0.5,
    },
  },
}

export const captionAnimation = {
  hide: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    transition: {
      delay: 0.75,
      duration: 0.75,
    },
  },
}

export const cardAnimation = {
  hide: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
}
