import { configureStore } from '@reduxjs/toolkit'
import slidesReducer from './slidesSlice'
import lightboxReducer from './lightboxSlice'
import slideFooterReducer from './slideFooterSlice'

const store = configureStore({
  reducer: {
    slideshow: slidesReducer,
    lightbox: lightboxReducer,
    slideFooter: slideFooterReducer,
  },
})

export type RootState = ReturnType<typeof store.getState>

export default store
