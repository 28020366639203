import React, { useEffect } from 'react'
import styled from 'styled-components/macro'
import 'bootstrap/dist/css/bootstrap.min.css'
import { pageAnimation } from '../utils/animations'
import YuliiaKravets from 'assets/photo/YuliiaKravets.jpg'
import { motion } from 'framer-motion'
import Macy from 'macy'
import queries from 'styles/breakpoints'

const Wrapper = styled(motion.main)`
  padding: 1.5rem;
  margin: 0 auto;
  max-width: var(--max-width);
  @media ${queries.tabletUp} {
    padding-top: 2.5rem;
  }
`

const List = styled(motion.div)`
  margin: 0;

  img {
    width: 100%;
  }
`

const macyOptions = {
  container: '#macy-grid',
  trueOrder: false,
  mobileFirst: true,
  useContainerForBreakpoints: true,
  margin: 24,
  boxSizing: 'border-box',
  columns: 1,
  breakAt: {
    1000: {
      margin: 16,
      columns: 2,
    },
    650: {
      margin: 16,
      columns: 1,
    },
  },
}

const AboutPage: React.FC = () => {
  useEffect(() => {
    new Macy(macyOptions)
  }, [])

  return (
    <Wrapper exit="exit" variants={pageAnimation} initial="hide" animate="show">
      <List id="macy-grid" className="contactContainer">
        <div>
          <div className="about-picture">
            <img src={YuliiaKravets} alt="About" />
          </div>
        </div>
        <div>
          <div className="about-description">
            <p>
              Hi, I'm Julia Kravets, an artist, designer, and lifelong learner
              with a profound passion for creativity.
            </p>
            <br />
            <p>
              I delved into the world of design since my school days, honing my
              skills and embracing various art forms, including{' '}
              <b className="bold-color">
                painting, graphics, abstraction, collage, and working with clay
              </b>
              .
            </p>
            <br />
            <p>
              In addition to my visual art, I channel my creativity into{' '}
              <b className="bold-color">
                crafting jewellery from natural materials
              </b>
              . This unique endeavour allows me to connect with nature
              personally and share my passion for sustainability through
              wearable art. My journey as an artist and designer is a testament
              to my lifelong dedication to creativity and learning.
            </p>
            <br />
            <p>
              My art is a reflection of my diverse influences and experiences.
              My work explores the complexities of figurative abstraction,
              weaving metaphorical narratives that touch on themes of
              displacement, belonging, and identity. A fusion of experiences and
              knowledge shapes my artistic practice. Drawing on a background in
              graphic design, I bring a digitally informed approach to my art
              while incorporating classical painting techniques, resulting in
              striking compositions that resonate with my accumulated memories
              and emotions.
            </p>
            <br />
            <ul>
              <li>
                My artistic journey began in the vibrant city of Zaporizhzhia,
                Ukraine, where I attended Primary Art School. I took a
                significant step toward pursuing my creative dreams by enrolling
                in the "Design" faculty at a local college in Zaporizhzhia.
              </li>
              <br />
              <li>
                A new chapter in my artistic journey as I joined the "Design"
                faculty at Kyiv National University of Technology and Design
                (KNUTD) in the bustling capital city of Kyiv. This academic
                endeavour further expanded my horizons and deepened my
                understanding of design principles and artistic expression.
              </li>
              <br />
              <li>
                Currently, I'm living, working, developing, and thriving in
                Poland.
              </li>
            </ul>
            <br />
            <p>
              My art is an ongoing exploration of self-expression and a
              reflection of the rich tapestry of my experiences.
            </p>
          </div>
        </div>
      </List>
    </Wrapper>
  )
}

export default AboutPage
