import {
  IconDefinition,
  IconName,
  IconPrefix,
} from '@fortawesome/fontawesome-svg-core'

export const simpleIconsTelegram: IconDefinition = {
  icon: [
    // SVG viewbox width (in pixels)
    448,

    // SVG viewbox height (in pixels)
    512,

    // Aliases (not needed)
    [],

    // Unicode as hex value (not needed)
    '',

    // SVG path data
    'M444.639 83.5748C444.308 81.9904 443.566 80.5163 442.483 79.2959C441.401 78.0754 440.016 77.1499 438.461 76.6088C432.606 75.4958 426.555 75.9141 420.916 77.8207C420.916 77.8207 31.2232 215.199 8.92144 230.403C4.16457 233.674 2.74366 235.612 1.75522 237.793C-2.07499 248.635 9.90991 253.421 9.90991 253.421L110.36 285.524C112.024 285.922 113.762 285.922 115.426 285.524C138.284 271.35 345.3 143.178 357.285 138.878C359.139 138.333 360.559 138.878 360.189 140.21C355.37 156.686 175.536 313.388 175.536 313.388C175.098 313.951 174.725 314.56 174.424 315.205L165.095 412.908C165.095 412.908 161.141 442.831 191.66 412.908C213.22 391.769 234.039 374.082 244.479 365.541C279.013 388.922 316.203 414.786 332.204 428.294C334.908 430.843 338.105 432.836 341.606 434.157C345.106 435.478 348.841 436.1 352.59 435.987C367.788 435.442 372.05 419.026 372.05 419.026C372.05 419.026 443.094 138.757 445.442 101.202C445.442 97.5673 445.998 95.1442 445.998 92.6607C446.035 89.5809 445.576 86.5147 444.639 83.5748Z',
  ],
  iconName: 'simple-icons-telegram' as IconName,
  prefix: 'simple-icons' as IconPrefix,
}
