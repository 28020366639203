import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import LogoIcon from 'assets/shared/kravetsLogo.svg'
import queries from 'styles/breakpoints'
import { NavbarProps } from 'models/navbar'

const StyledLink = styled(Link)`
  line-height: 1;
`

const Wrapper = styled.div`
  width: 8.0625rem;

  svg {
    width: 100%;
    height: auto;
  }

  @media ${queries.laptopUp} {
    width: 10.625rem;
  }
`

const Logo: React.FC<NavbarProps> = ({ updateNavbar }): JSX.Element => {
  const handleLogoClick = () => {
    updateNavbar()
  }
  return (
    <StyledLink to="/" onClick={handleLogoClick}>
      <Wrapper>
        <img src={LogoIcon} alt="Galleria Logo - home" />
      </Wrapper>
    </StyledLink>
  )
}

export default Logo
