import styled from 'styled-components/macro'
import { motion } from 'framer-motion/dist/framer-motion'

export const List = styled(motion.div)`
  margin: 0;

  img {
    width: 100%;
  }
`

export const Figure = styled(motion.figure)`
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  justify-content: center;
  margin: auto;
  @media (min-width: 1000px) {
    width: 21rem;
  }
`

export const Button = styled(motion.button)`
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #555;

  position: relative;
  top: -2rem;
  left: 2rem;
  background-color: rgba(0, 0, 0, 0.75);
  border: none;
  padding: 0.875rem 1rem;
  text-transform: uppercase;
  color: var(--color-white);
  font-size: 0.625rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.2s linear;

  & svg g {
    transition: fill 0.2s linear;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.85);
    color: var(--color-black);
  }

  &:hover svg g {
    fill: var(--color-black);
  }

  span {
    margin-left: 0.875rem;
    letter-spacing: 0.13375rem;
  }
`

export const Description = styled.p`
  text-align: justify;
  font-weight: bold;
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.65);
  line-height: 2;
  z-index: 2;
  position: relative;
  margin-top: 3rem;
  margin-bottom: 3rem;
`
export const PictureParams = styled.p`
  font-weight: bold;
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.65);
  line-height: 2;
  margin-top: 0.25rem;
  margin-bottom: 2.25rem;
  z-index: 2;
  position: relative;
`
export const GoToShop = styled.div`
  position: relative;
  margin-bottom: 5rem;
  @media (max-width: 650px) {
    margin-bottom: 0;
  }
`

export const Link = styled.a`
  font-size: 0.9rem;
  font-weight: bold;
  color: var(--color-grey-1);
  letter-spacing: 0.1206rem;

  @media (max-width: 650px) {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -10px 0 0 -50px;
  }
`
export const Name = styled.p`
  color: rgba(0, 0, 0, 0.65);
  display: block;
  margin-top: 1.33em;
  margin-bottom: 0.25rem;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
  font-size: 1.5rem;
`
