import { StyledHeader } from './Header.styles'
import { pageAnimation } from 'utils/animations'
import Navbar from 'components/Header/Navbar'

const Header = (): JSX.Element => {
  return (
    <StyledHeader
      exit="exit"
      variants={pageAnimation}
      initial="hide"
      animate="show"
    >
      <Navbar />
    </StyledHeader>
  )
}

export default Header
