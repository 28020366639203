import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`

  :root {
    --color-black: #000;
    --color-grey-1: #7D7D7D;
    --color-grey-9: #E5E5E5;
    --color-grey-10: #F3F3F3;
    --color-white: #FFF;
    --max-width: 88rem;
  }

  /* Box sizing rules */
  html {
    box-sizing: border-box;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  /* Remove default margin */
  body,
  h1,
  h2,
  h3,
  h4,
  p,
  figure,
  blockquote,
  dl,
  dd {
    margin: 0;
  }


  /* Set core body defaults */
  body {
    min-height: 100vh;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
  }

  /* A elements that don't have a class get default styles */
  a:not([class]) {
    text-decoration-skip-ink: auto;
  }

  /* Make images easier to work with */
  img,
  picture {
    max-width: 100%;
    display: block;
  }

  #macy-grid {
    list-style-type: none;
    padding: 0;
  }

  /* Inherit fonts for inputs and buttons */
  input,
  button,
  textarea,
  select {
    font: inherit;
  }

  /* Remove all animations and transitions for people that prefer not to see them */
  @media (prefers-reduced-motion: reduce) {
    html:focus-within {
      scroll-behavior: auto;
    }

    *,
    *::before,
    *::after {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.01ms !important;
      scroll-behavior: auto !important;
    }
  }

  body {
    font-family: 'Montserrat', serif;
    color: var(--color-black);
    overflow-x: hidden;
    position: relative;
    font-weight: lighter;
  }

  a:focus:not(:focus-visible),
  button:focus:not(:focus-visible) {
    outline: none;
  }

  .sr-only {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;
  }

  .picture {
    paddinf
  }

  .dropdown-item.active, .dropdown-item:active {
    color: black;
    text-decoration: none;
    background-color: white;
  }

  .dropdown-menu {
    border: 0;
    border-radius: 0;
    font-size: 0.875rem;
  }

  .nav-item.dropdown:hover .dropdown-menu {
    @media (min-width: 1000px) {
      display: block;
      margin-top: 0;
      }
    
}

.nav-item.dropdown .dropdown-menu {
  margin-top: 0;
}
  .contactRow {
    @media (min-width: 1000px) {
      margin-top: 9.5rem;
    }
  }

  .contactRow h4 {
    color: rgba( 0,0,0 , 0.65);
  }

  .contactRow p {
    color: rgba( 0,0,0 , 0.65);
  }
  .contactWrap {
    @media (min-width: 1000px) {
      padding-left: 40%;
      margin-top: 1.1rem;
    }
    @media (max-width: 650px) {
      margin-top: 3rem;    
    }

  }
  

  .contactData {
    margin-top: 0.5rem!important;
  }

  .navbar-toggler {
    border: none;
  }

  .navbar-toggler:focus {
    box-shadow: none;
  }

  .navbar-toggler-icon {
    background-image: url("./toogle-icon.svg");
  }

  .about-picture {
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    justify-content: center;
    margin: auto;
  @media (min-width: 1000px) {
    width: 21rem;
  }
  }

  .about-description {
    text-align: justify;
    font-weight: bold;
    font-size: 0.875rem;
    color: rgba(0, 0, 0, 0.65);
    line-height: 2;
    z-index: 2;
    position: relative;
    margin-bottom: 3rem;
  }

  .first-sentence {
    padding-bottom: 1.2rem;
  }

  .bold-color {
    color: black;
  }

  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.43em;
    content: "";
    border-radius: 50%;
    border: 1px solid #000;
    width: 0.1cm;
    height: 0.1cm;
    background: black;
  }



.nav-link:focus-visible {
  box-shadow: none;
}
:focus-visible {
  outline: 0;
}

`
