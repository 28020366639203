import styled from 'styled-components/macro'
import { motion } from 'framer-motion'

export const Wrapper = styled(motion.footer)`
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 1.5rem;
  margin: 0 auto;
  top: auto;

  @media (max-width: 650px) {
    position: fixed;
    bottom: 50%;
    width: 100%;
    padding: 0;
  }
`

export const Footer = styled(motion.div)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
`

export const ArrowButtonRight = styled(motion.button)`
  outline: 0;
  order: 2;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  transition: fill 0.2s linear;

  & svg g {
    transition: fill 0.2s linear;
  }

  &:hover svg g {
    fill: var(--color-black);
  }
`
export const ArrowButtonLeft = styled(motion.button)`
  order: 1;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  transition: fill 0.2s linear;

  & svg g {
    transition: fill 0.2s linear;
  }

  &:hover svg g {
    fill: var(--color-black);
  }
`
