import { GlobalStyles } from 'styles/GlobalStyles'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Gallery from 'pages/Gallery'
import Slideshow from 'pages/Slideshow'
import ContactPage from 'pages/Contact'
import Header from 'components/Header/'
import LightBox from 'components/Gallery/organisms/Lightbox'
import SlideFooter from './components/Gallery/molecules/SlideFooter'
import AboutPage from 'pages/About'

function App(): JSX.Element {
  return (
    <Router>
      <GlobalStyles />
      <Header />
      <LightBox />
      <Switch>
        <Route path="/" exact>
          <Gallery />
        </Route>
        <Route path="/graphic" exact>
          <Gallery type="Graphic" />
        </Route>
        <Route path="/collage" exact>
          <Gallery type="Collage" />
        </Route>
        <Route path="/painting" exact>
          <Gallery type="Painting" />
        </Route>
        <Route path="/slideshow">
          <Slideshow />
          <SlideFooter />
        </Route>
        <Route path="/about">
          <AboutPage />
        </Route>
        <Route path="/contact">
          <ContactPage />
        </Route>
      </Switch>
    </Router>
  )
}
export default App
