import 'bootstrap/dist/css/bootstrap.min.css'
import Logo from 'components/Gallery/atoms/Logo'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import { Navbar as BNavbar, Nav, NavDropdown } from 'react-bootstrap'
import { useState } from 'react'

const StyledLink = styled(Link)`
  text-decoration: none;
`

const Navbar = (): JSX.Element => {
  const [expand, setExpanded] = useState(false)

  const updateNavbarExpand = () => {
    setExpanded(false)
  }

  return (
    <BNavbar collapseOnSelect expand="lg" expanded={expand}>
      <Logo updateNavbar={updateNavbarExpand} />
      <BNavbar.Toggle
        aria-controls="basic-navbar-nav"
        onClick={() => setExpanded(expand ? false : true)}
      />
      <BNavbar.Collapse id="basic-navbar-nav" className="justify-content-end">
        <Nav className="ml-auto">
          <NavDropdown
            title="Gallery"
            id="basic-nav-dropdown"
            style={{
              border: 'none',
            }}
            renderMenuOnMount={true}
          >
            <StyledLink
              onClick={() => setExpanded(false)}
              to="/"
              href="/"
              className="dropdown-item"
            >
              All
            </StyledLink>
            <StyledLink
              onClick={() => setExpanded(false)}
              to="/collage"
              href="/collage"
              className="dropdown-item"
            >
              Collage
            </StyledLink>
            <StyledLink
              onClick={() => setExpanded(false)}
              to="/graphic"
              href="/graphic"
              className="dropdown-item"
            >
              Graphic
            </StyledLink>
            <StyledLink
              onClick={() => setExpanded(false)}
              to="/painting"
              href="/painting"
              className="dropdown-item"
            >
              Painting
            </StyledLink>
          </NavDropdown>{' '}
          <StyledLink
            onClick={() => setExpanded(false)}
            to="/about"
            className="nav-item"
          >
            <li className="nav-link">About</li>
          </StyledLink>
          <StyledLink
            onClick={() => setExpanded(false)}
            to="/contact"
            className="nav-item"
          >
            <li className="nav-link">Contacts</li>
          </StyledLink>
        </Nav>
      </BNavbar.Collapse>
    </BNavbar>
  )
}

export default Navbar
