import React, { useState } from 'react'
import styled from 'styled-components/macro'
import emailjs from 'emailjs-com'
import { TextField } from '@mui/material'
import 'bootstrap/dist/css/bootstrap.min.css'
import { pageAnimation, messageIconAnimation } from '../utils/animations'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { simpleIconsTelegram } from 'assets/shared/Telegram'
import PoopIcon from 'assets/shared/poop.svg'
import SmileIcon from 'assets/shared/smile.svg'
import { motion } from 'framer-motion'
import Macy from 'macy'
import { useEffect } from 'react'

const Wrapper = styled(motion.main)`
  padding: 1.5rem;
  margin: 0 auto;
  max-width: var(--max-width);
`

const List = styled(motion.div)`
  margin: 0;

  img {
    width: 100%;
  }
`

const macyOptions = {
  container: '#macy-grid',
  trueOrder: false,
  mobileFirst: true,
  useContainerForBreakpoints: true,
  margin: 24,
  boxSizing: 'border-box',
  columns: 1,
  breakAt: {
    1000: {
      margin: 16,
      columns: 2,
    },
    650: {
      margin: 16,
      columns: 1,
    },
  },
}
const ContactPage: React.FC = () => {
  const [formState, setFormState] = useState<'initial' | 'success' | 'error'>(
    'initial'
  )

  useEffect(() => {
    new Macy(macyOptions)
  }, [])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    emailjs
      .sendForm(
        'service_3rdxct6',
        'template_kt8jcmu',
        e.target as HTMLFormElement,
        'ZZwK40w65w4kKH9o6'
      )
      .then(response => {
        setFormState('success')
      })
      .catch(error => {
        setFormState('error')
        console.error('Email sending failed:', error)
      })
  }

  return (
    <Wrapper exit="exit" variants={pageAnimation} initial="hide" animate="show">
      <List id="macy-grid" className="contactContainer">
        <div className="contactRow">
          {formState === 'success' && (
            <motion.div
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={messageIconAnimation}
            >
              <div>
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ marginTop: '1.1rem' }}
                >
                  <img
                    src={SmileIcon}
                    alt="Success message logo"
                    style={{ height: 140, width: 140 }}
                  />
                </div>
                <div className="d-flex align-items-center justify-content-center mt-4">
                  <h4>Done!</h4>
                </div>
              </div>
            </motion.div>
          )}
          {formState === 'error' && (
            <motion.div
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={messageIconAnimation}
            >
              <div>
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ marginTop: '1.1rem' }}
                >
                  <img
                    src={PoopIcon}
                    alt="Message failed logo"
                    style={{ height: 140, width: 140 }}
                  />
                </div>
                <div className="d-flex align-items-center justify-content-center mt-5">
                  <h4>Unable to send the message.</h4>
                </div>
                <div className="d-flex align-items-center justify-content-center">
                  <p>Please try again later or reach me by phone or email.</p>
                </div>
              </div>
            </motion.div>
          )}
          {formState !== 'success' && formState !== 'error' && (
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <TextField
                  id="standard-basic"
                  label="Name"
                  variant="standard"
                  type="text"
                  className="form-control"
                  name="name"
                  color="success"
                  required
                />
              </div>
              <div className="mb-3">
                <TextField
                  id="standard-basic"
                  label="Email"
                  variant="standard"
                  type="email"
                  className="form-control"
                  color="success"
                  name="email"
                  required
                />
              </div>
              <div className="mb-3">
                <TextField
                  label="Message"
                  multiline
                  type="text"
                  variant="standard"
                  minRows={3}
                  id="standard-basic"
                  className="form-control"
                  color="success"
                  name="message"
                  required
                />
              </div>
              <div className="form-group mb-3 mt-5">
                <button
                  type="submit"
                  className="btn btn-outline-dark rounded-0"
                >
                  Submit
                </button>
              </div>
            </form>
          )}
        </div>
        <div className="contactRow">
          <div className="contactWrap">
            <div className="contact-us">
              <h4>Contact</h4>
              <p className="contactData">
                +48 508 620 419
                <br />
                <a
                  href="mailto:jkravets.art@gmail.com"
                  className="link-success"
                >
                  jkravets.art@gmail.com
                </a>
              </p>
            </div>
            <div className="social-icons mb-3 mt-5">
              <h4>Follow</h4>
              <div className="social-icons-container">
                <a
                  href="https://www.instagram.com/jkravets.art/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon
                    icon={faInstagram}
                    size="xl"
                    color="gray"
                    className="me-3 mt-2"
                  />
                </a>
                <a
                  href="https://wa.me/+48508620419"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon
                    icon={faWhatsapp}
                    size="xl"
                    color="gray"
                    className="me-3 mt-2"
                  />
                </a>
                <a href="https://t.me/yyulets" target="_blank" rel="noreferrer">
                  <FontAwesomeIcon
                    icon={simpleIconsTelegram}
                    size="xl"
                    color="gray"
                    className="me-3 mt-2"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </List>
    </Wrapper>
  )
}

export default ContactPage
