import { createSlice } from '@reduxjs/toolkit'
import { RootState } from 'store'

const slideFooterSlice = createSlice({
  name: 'slideFooter',
  initialState: {
    isSlideFooterVisible: true,
  },
  reducers: {
    setSlideFooterVisibility: (state, action) => {
      state.isSlideFooterVisible = action.payload
    },
  },
})

export const { setSlideFooterVisibility } = slideFooterSlice.actions

export const selectSlideFooterVisibility = (state: RootState): boolean =>
  state.slideFooter.isSlideFooterVisible

export default slideFooterSlice.reducer
